import React from 'react'
import logo from '../assets/logo.svg'
import {Link} from "react-router-dom"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faShuttleSpace, faUserAstronaut} from '@fortawesome/free-solid-svg-icons';
import '../css/home.css'

export default function Home() {
    return (
        <div className='container-fluid min-vh-100 bg-dark px-0'>
            <nav className="navbar">
                <div className="container d-flex justify-content-between align-items-center">
                    <div className='d-flex align-items-center'>
                        <a className="navbar-brand d-flex justify-content-center align-items-center" href="/">
                            <img src={logo} alt="logo" className='me-2' width="50" />
                            <span className='fw-bold' style={{color: '#849bff'}}>UnifiedAuth</span>
                        </a>

                        <a className='ms-5 me-3 text-decoration-none text-white'
                           style={{fontSize: 14, cursor: 'pointer'}}>
                            Documentation
                        </a>
                        <a className='mx-3 text-decoration-none text-white'
                           style={{fontSize: 14, cursor: 'pointer'}}>
                            Pricing
                        </a>
                    </div>

                    <div className='d-flex align-items-center align-items-center'>
                        <Link to='/auth'
                              style={{textDecoration: 'none', fontSize: 16, color: '#849bff'}}>
                            Log in
                        </Link>

                        <div className='rounded-circle mx-3' style={{backgroundColor: '#849bff', height: 3, width: 3}}></div>

                        <Link to='/auth/register'
                              style={{textDecoration: 'none', fontSize: 16, color: '#849bff'}}>
                            Sign up
                        </Link>
                    </div>

                </div>
            </nav>

            <div className='container-fluid p-0'>
                <div className='row'>
                    <div className='col-12 py-5 position-relative'>
                        <div className='d-flex justify-content-around position-relative' style={{zIndex: 10}}>
                            <div className='mt-4' style={{width: 600}}>
                                <h1 className='text-white mt-5 pt-5 mb-4' style={{fontSize: 50, fontWeight: 900}}>
                                    Never build authentication again
                                </h1>
                                <p className='text-white mb-4' style={{fontSize: 24, fontWeight: 300}}>
                                    Easy, fast, and <span className='fw-bold' style={{color: '#849bff'}}>secure</span> access for your users.
                                </p>

                                <button className='btn text-white px-4 mb-2' style={{backgroundColor: '#849bff', borderColor: '#849bff'}}>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <span className='me-3'>Get started</span>
                                        <FontAwesomeIcon className='translate' icon={faShuttleSpace} style={{fontSize: 16}} />
                                    </div>
                                </button>

                                <p className='text-light' style={{fontSize: 12}}>
                                    Start building with UnifiedAuth for free
                                </p>
                            </div>

                            <div className='mt-5 floating' style={{width: 350}}>
                                <div className='bg-white rounded border shadow-lg w-100 p-4'>
                                    <h1 className='fw-bold mb-4 text-center' style={{ fontSize: 36 }}>
                                        Log in
                                    </h1>

                                    <div className='mb-3'>
                                        <div className='form-floating'>
                                            <input
                                                type='email'
                                                className='form-control'
                                                id='emailAddress'
                                                placeholder='name@example.com'
                                                aria-describedby='emailValidationFeedback'
                                            />

                                            <label htmlFor='emailAddress'>Email address</label>
                                        </div>
                                    </div>

                                    <div className='mb-2'>
                                        <div className='form-floating'>
                                            <input
                                                type='password'
                                                className='form-control'
                                                id='password'
                                                placeholder='Password'
                                                aria-describedby='passwordValidationFeedback'
                                            />

                                            <label htmlFor='password' className='form-label'>
                                                Password
                                            </label>
                                        </div>
                                    </div>

                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                        <div className='form-check'>
                                            <input
                                                className='form-check-input'
                                                style={{ width: 14, height: 14, marginTop: 5 }}
                                                type='checkbox'
                                                value=''
                                                id='flexCheckDefault'
                                            />
                                            <label
                                                className='form-check-label'
                                                style={{ fontSize: 14 }}
                                                htmlFor='flexCheckDefault'
                                            >
                                                Remember me
                                            </label>
                                        </div>

                                        <a
                                            href='#'
                                            style={{
                                                fontSize: 14,
                                                textDecoration: 'none',
                                                color: '#849bff'
                                            }}
                                        >
                                            Forgot password?
                                        </a>
                                    </div>

                                    <div className='pb-4 mb-3 border-bottom'>
                                        <button
                                            className='btn btn-primary w-100 py-2'
                                            style={{ backgroundColor: '#849bff', borderColor: '#849bff' }}
                                        >
                                            Log in
                                        </button>
                                    </div>

                                    <div className='d-flex justify-content-center'>
                                        <span className='me-2' style={{ fontSize: 14 }}>
                                          Don't have an account?
                                        </span>
                                        <a  href='#'
                                            className='fw-bold'
                                            style={{ textDecoration: 'none', fontSize: 14, color: '#849bff' }}
                                        >
                                            Sign up
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='position-absolute' style={{height: 500, width: '100%', top: 350}}>
                            <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1600 500'>
                                <rect fill='#212529' width='100%' height='500'/>
                                <polygon fill='#ffffff'  points='957 50 539 500 1396 500'/>
                                <polygon fill='#dadada'  points='957 50 872.9 500 1396 500'/>
                                <polygon fill='#e9eaff'  points='-60 500 398 262 816 500'/>
                                <polygon fill='#c6c7d9'  points='337 500 398 262 816 500'/>
                                <polygon fill='#d2d6ff'  points='1203 146 1552 500 876 500'/>
                                <polygon fill='#b2b5d8'  points='1203 146 1552 500 1162 500'/>
                                <polygon fill='#bac2ff'  points='641 295 886 500 367 500'/>
                                <polygon fill='#9da3d7'  points='587 500 641 295 886 500'/>
                                <polygon fill='#a1aeff'  points='1710 500 1401 232 1096 500'/>
                                <polygon fill='#8691d5'  points='1710 500 1401 232 1365 500'/>
                                <polygon fill='#849bff'  points='1210 500 971 287 725 500'/>
                                <polygon fill='#6d80d3'  points='943 500 1210 500 971 287'/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
