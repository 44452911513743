import {AuthRouter, RequiresAuth} from 'unified-auth-react'
import {Route, Routes, useNavigate} from "react-router-dom";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import React, {useEffect, useState} from "react";
import logo from './assets/logo.svg'
import {useCookies} from "react-cookie";
import Account from "./pages/Account";

const theme = {
    primaryColor: '#849bff'
}

function App() {
    const navigate = useNavigate()
    const [cookies, setCookie] = useCookies(['ua-auth-cookie']);
    const [jwt, setJwt] = useState()

    useEffect(() => {
        console.log(cookies['ua-auth-cookie'])
        setJwt(cookies['ua-auth-cookie'])
    }, [])

    function handleLoginCallback(res) {
        console.log(res)
        if (res.status === 200) {
            res.json().then(body => {
                setJwt(body.jwt)
                setCookie('ua-auth-cookie', body.jwt, {
                    path: '/',
                    secure: true
                })
                navigate('dashboard')
            })
        }
    }

    return (
        <Routes>
            <Route exact path='/' element={<Home />} />
            <Route path='auth/*' element={
                <AuthRouter logo={logo}
                            baseUrl={process.env.REACT_APP_AUTH_BASE_URL}
                            loginCallback={(res) => handleLoginCallback(res)}
                            theme={theme}/>}
            />
            <Route path='/dashboard' element={
                <RequiresAuth baseUrl={process.env.REACT_APP_AUTH_BASE_URL}
                              jwt={jwt ? jwt : cookies["ua-auth-cookie"]}
                              redirectUrl={'/auth'}>
                    <Dashboard />
                </RequiresAuth>
            } />
            <Route path='/account/:id' element={
                <RequiresAuth baseUrl={process.env.REACT_APP_AUTH_BASE_URL}
                              jwt={jwt ? jwt : cookies["ua-auth-cookie"]}
                              redirectUrl={'/auth'}>
                    <Account />
                </RequiresAuth>
            } />
        </Routes>
    );
}

export default App;
