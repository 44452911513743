import React, {useEffect, useState} from 'react';
import logo from "../assets/logo.svg";
import {useNavigate} from "react-router-dom";
import {useCookies} from "react-cookie";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserAstronaut} from '@fortawesome/free-solid-svg-icons';

import PlaidLinkButton from "../components/PlaidLinkButton";

export default function Dashboard() {
    const navigate = useNavigate()
    const [cookies, setCookie, removeCookie] = useCookies(['ua-auth-cookie'])
    const [user, setUser] = useState({email: '', firstName: '', lastName: ''})
    const [token, setToken] = useState()
    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        const cookie = cookies['ua-auth-cookie']
        let base64Url = cookie.split('.')[1]
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
        console.log(JSON.parse(jsonPayload))
        let body = JSON.parse(jsonPayload)
        setUser({email: body.email, firstName: body.firstName, lastName: body.lastName})

        createPlaidLinkToken()
        getPlaidAccounts()
    }, [])

    function logout() {
        removeCookie('ua-auth-cookie')
        navigate('/auth', {replace: true})
    }

    function getPlaidAccounts() {
        fetch('http://localhost:3001/plaid/auth/get', {
            method: 'POST',
            body: JSON.stringify({
                uuid: "76521c6b-795c-4ae1-a0fd-dfec26983dee"
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setAccounts(data.accounts)
            });
    }

    function createPlaidLinkToken() {
        fetch('http://localhost:3001/plaid/link/token/create/76521c6b-795c-4ae1-a0fd-dfec26983dee')
            .then(res => res.json())
            .then(data => {
                console.log(data)
                setToken(data.token)
            });
    }

    function sendPublicToken(token) {
        fetch('http://localhost:3001/plaid/item/public_token/exchange', {
            method: 'POST',
            body: JSON.stringify({
                token: token,
                uuid: '76521c6b-795c-4ae1-a0fd-dfec26983dee'
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(res => res.json())
            .then(data => {
                console.log(data)
            });
    }

    function navigateToAccount(accountId) {
        navigate('/account/' + accountId)
    }

    let formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <div className='container-fluid px-0'>
            <nav className="navbar">
                <div className="container d-flex justify-content-between">
                    <a className="navbar-brand d-flex justify-content-center align-items-center" href="/">
                        <img src={logo} alt="logo" className='me-2' width="50" />
                        <span className='fw-bold' style={{color: '#849bff'}}>UnifiedAuth</span>
                    </a>

                    <div className='d-flex align-items-center'>
                        <span className='me-3' style={{fontWeight: 500}}>{user.firstName}</span>
                        <div className="dropdown">
                            <button className="btn btn-secondary rounded-circle d-flex justify-content-center align-items-center p-0"
                                    type="button" id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown" aria-expanded="false"
                                    style={{backgroundColor: '#849bff', borderColor: '#849bff', width: 35, height: 35}}>
                                <FontAwesomeIcon icon={faUserAstronaut} style={{fontSize: 16}} />
                            </button>
                            <ul className="dropdown-menu dropdown-menu-lg-end mt-1" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a className="dropdown-item" href="#">
                                        My Profile
                                        <div style={{fontSize: 14}}>{user.email}</div>
                                    </a>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li><button className="bg-transparent border-0 dropdown-item" onClick={logout}>Logout</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>

            {/*<div className='container'>*/}
            {/*    <div className='row mb-3'>*/}
            {/*        <div className='col-12'>*/}
            {/*            <h1 style={{fontSize: 24}}>Accounts</h1>*/}
            {/*        </div>*/}
            {/*    </div>*/}

            {/*    <div className='row'>*/}
            {/*        {accounts.map(account => (*/}
            {/*            <div key={account['account_id']}*/}
            {/*                 className='col-12 col-lg-3 mb-3' onClick={() => navigateToAccount(account['account_id'])}>*/}
            {/*                <div className='border rounded shadow-sm text-white p-4'*/}
            {/*                     style={{ backgroundColor: '#849bff', cursor: 'pointer'}}>*/}
            {/*                    <h2 style={{fontSize: 20}}>{account.name}</h2>*/}
            {/*                    <div>Current: {formatter.format(account['balances']['current'])}</div>*/}
            {/*                    <div>Available: {formatter.format(account['balances']['available'])}</div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        ))}*/}

            {/*        <div className='col-12 col-lg-3 mb-3 align-self-stretch'>*/}
            {/*            <div className='d-flex justify-content-center align-items-center border rounded shadow-sm text-white h-100'*/}
            {/*                 style={{ backgroundColor: '#849bff', cursor: 'pointer'}}>*/}
            {/*                <PlaidLinkButton token={token}*/}
            {/*                                 onSuccess={(token) => sendPublicToken(token)} />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}
